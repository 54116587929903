import http from "./http";

export default {
  getalldata(id, filterAll) {
    return http
      .get("report/" + id + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  getallcustomer(cus_id) {
    return http
      .get("user_logsByCustomer/" + cus_id)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  searhbydate(date, date1) {
    return http
      .get("factory_log/query?start=" + date + "&end=" + date1)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  getreportemployee(item, item1) {
    return http
      .post("reportByEmployee/" + item, { date: item1 })
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },

  getdata(id, filterAll = '') {
    return http
      .get("/report/member/" + id + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  getMemberSum(filterAll) {
    return http
      .get("member-summary" + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  getMemberSumCheckin(filterAll) {
    return http
      .get("member-sum-checkin" + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  exporExcelLog(id, filterAll) {
    return http
      .get("/export/log-report/" + id + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  exportMemberSum(filterAll) {
    return http
      .get("/export/member-summary" + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
  exportMemberChkinSum(filterAll) {
    return http
      .get("/export/member-checkin-summary" + filterAll)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch(function (error) {
        return Promise.reject(error);
      });
  },
};

